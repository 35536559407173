import React from "react";
import SwiperSlider from "./hooks/swiperslider.js";
const Reviews = () => {
  const slidesData = [
    {
      content:
        "“Choosing BytezTech was the best decision we made. Their personalized approach, attention to detail, and seamless integration saved us time and resources, resulting in substantial cost savings.”",
      img: "./assets/img/avatars/Kshitij_Mall.webp",
      img2x: "./assets/img/avatars/Kshitij_Mall.webp 2x",
      name: "Kshitij Mall",
      position: "Founder - The Aatmanirbhar Bharat",
    },
    {
      content:
        "“BytezTech transformed our business with its cutting-edge software solution. We experienced a 30% increase in productivity and a significant boost in customer satisfaction.”",
      img: "./assets/img/avatars/Erik.webp",
      img2x: "./assets/img/avatars/Erik.webp 2x",
      name: "Erik Voges",
      position: "CEO - Asset Control",
    },
  ];

  return (
    <section className="wrapper bg-light">
      <div className="container py-16 py-md-18">
        <div className="card shadow-lg mt-n21 mt-md-n23">
          <div className="row gx-0">
            <div className="container py-5">
              <div className="row gx-lg-8 gx-xl-12 gy-6 align-items-center">
                <div className="col-lg-7 order-lg-2">
                  <figure>
                    <img
                      className="w-auto"
                      src="./assets/img/illustrations/i4.webp"
                      srcSet="./assets/img/illustrations/i4@2x.webp 2x"
                      alt="Illustration"
                    />
                  </figure>
                </div>
                <div className="col-lg-5 mt-12 ">
                  <SwiperSlider
                    slides={slidesData.map((slide) => (
                      <blockquote
                        className="icon icon-top fs-lg text-center "
                        key={slide.name}
                      >
                        <p>{slide.content}</p>
                        <div className="blockquote-details justify-content-center text-center">
                          <img
                            className="rounded-circle w-12"
                            src={slide.img}
                            srcSet={slide.img2x}
                            alt={slide.name}
                          />
                          <div className="info">
                            <h5 className="mb-1">{slide.name}</h5>
                            <p className="mb-0">{slide.position}</p>
                          </div>
                        </div>
                      </blockquote>
                    ))}
                    pagination={{ clickable: true }}
                    navigation={false}
                    loop={false}
                    autoplay={{ delay: 5000 }}
                    spaceBetween={30}
                    nav={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
